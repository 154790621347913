.icon {
  color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3));
  cursor: pointer;
}

.name {
  font-family: Greycliff CF, var(--mantine-font-family);
}

.card {
  background-color: #393f4b;
}
